import '../App.css';
import React, { useState, useEffect } from 'react';
import Accordion from './Accordion';

const SixStarRating = ({ rating, onChange, rewards }) => {
  const [displayRating, setDisplayRating] = useState(rating || 0);
  const [selectedRating, setSelectedRating] = useState(rating || 0);

  useEffect(() => {
    setSelectedRating(rating);
    setDisplayRating(rating);
  }, [rating]);

  const handleMouseEnter = (index) => {
    setDisplayRating(index + 1);
  };

  const handleMouseLeave = () => {
    setDisplayRating(selectedRating);
  };

  const handleClick = (index) => {
    setSelectedRating(index + 1);
    setDisplayRating(index + 1);
    onChange && onChange(index + 1);
  };

  const stars = Array.from({ length: 6 }, (_, index) => {
    const fillPercentage =
      displayRating > index + 1
        ? 100
        : displayRating > index && displayRating <= index + 1
        ? (displayRating - index) * 100
        : 0;

    return (
      <div
        key={index}
        className="starBox"
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(index)}
        style={{
          '--fill': `${fillPercentage}%`,
        }}
      >
        ★
      </div>
    );
  });

  return <div>
  {stars}
  <span class="reward">(+{rewards?.rate_reward} NCO)</span>
  <Accordion trigger={"Read about ratings..."} text={"This action enables a user to rate an affiliate/solution by giving an overall satisfaction rating to the solution (min=1, max=6). By rating the solution, a user is rewarded in NCO token. In order to rate, a user has to be verified. See the action eosden::verify. Points are added to an affiliate/solution when a user gives a rating. \n \n Formula: Total points of an affiliate/solution / count of users rating = Avg. Rating"}/>
  </div>;
};

export default SixStarRating;