import React, { useState } from 'react';
import '../App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Accordion from './Accordion';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50vw",
    bgcolor: 'white',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "8px",
    '@media (max-width: 768px)': {
      width: '80vw',
    }
  };

function Affiliate({rewards, setView, openaffiliate, handleCloseaffiliate, handleOpenaffiliate, accountname, sweetalert}) {
  const copyUrl = () => {
    const url = "https://web3wax.eosde.net/affiliate/" + accountname;
    
    navigator.clipboard.writeText(url)
      .then(() => {
        sweetalert("Affiliate link copied to clipboard!");
      })
      .catch(err => {
        // Maybe user denied clipboard permissions
        sweetalert("Failed to copy link!");
      });
  }
  return (
    <>
    <button onClick={()=> handleOpenaffiliate()} className="menubutton">Referrals</button>
        <Modal
        open={openaffiliate}
        onClose={handleCloseaffiliate}
        >
        <Box sx={style}>
        <div><b style={{alignSelf:"flex-start"}}>Refer new users to EOSDen and earn NCO! </b><br/> You are rewarded:
        <li><b>Referring new affiliate - </b> {rewards?.solution_referral_reward} NCO($20 worth)</li>
        <li><b>Referring new verified user - </b> {rewards?.user_referral_reward} NCO($5 worth)</li>
        </div>
        <div>Just share your link below, if the new user or affiliate is accepted, you will receive your rewards.</div>
        <hr className="solution-splitter"/>
        <TextField style={{width:"100%"}} value={"https://web3wax.eosde.net/affiliate/" + accountname}></TextField>
        <button className="modal-submit" onClick={()=> copyUrl()}>Copy referral link</button>
        </Box>
        </Modal>
        </>
  );
}

export default Affiliate;
