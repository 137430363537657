import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";

function SubmitComment({rewards, triggerfetch, setTriggerFetch, sweetalert, activeUser, accountname, affiliate}) {
  const [review, setReview] = useState("")
  const submitComment = async (rating) => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "review",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                user: accountname,
                affiliate:affiliate,
                comment: review
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }
  return (
    <div style={{position:"relative"}}>
    <textarea className="submit-comment" placeholder="Review" onBlur={(e) => setReview(e.target.value)}>
    </textarea>
    <button
    style={{ position: 'absolute', bottom: '10px', right: '10px' }}
    className="submit-comment-button"
    onClick={() => submitComment()}
    >Submit <br/><span style={{fontSize:"10px"}}>(+{rewards?.review_reward} NCO)</span></button>
    </div>
  );
}

export default withUAL(SubmitComment);
