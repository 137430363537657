import React from 'react';
import '../App.css';

//Component for the categories menu. Renders a button for each category in categories.
const Feedmenu = ({ category, setCategory, categories, view}) => {

  function handleClick(newCategory) {
    setCategory(newCategory);
    
    // Set the new URL to /filter/{category}
    const newURL = `${window.location.origin}/category/${newCategory}`;
    window.history.pushState({}, '', newURL);
  }
  
  return (
    <div className="feedmenu">
      {categories?.map((value, index) =>{
        return(<div key={index} className={category==value ? "feedmenu-category-selected" : "button-1"} onClick={() => handleClick(value)}><a className={category==value ? "" : "button-2"}><span className={category==value ? "" : "button-3"}>{value}</span></a></div>)
      })}
    </div>
  );
};

export default Feedmenu;