import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import { timeAgo } from '../Helpers/timeAgo';
import { sendEmail } from '../Helpers/sendEmail';
import Linkify from 'react-linkify';

function Comment({adminrights, sweetalert, activeUser, comment, accountname, affiliate, setTriggerFetch, triggerfetch, userType, userData}) {
  
  //function to find the email of the verified user who left the review, so if Admin deletes it, we can email them.
  function findUserEmail(username, data) {
    const user = data.find((item) => item.user === username);
    if (user) {
      const emailObj = user.socials.find((social) => social.key === "email");
      if (emailObj) {
        return emailObj.value;
      }
    }
    return null;
  }

  const URL_REGEX = /(https?:\/\/|ftp:\/\/|www\.)[^\s]+(?:\.[^\s]+)*(?!\.)(?:\/[^\s]*)?/g;
  const getUrl = (part) => { 
  return(part.startsWith('http://') || part.startsWith('https://') || part.startsWith('ftp://')? 
  part : 'https://' + part)
  }
  const renderText = txt =>
    txt
      .split(" ")
      .map(part =>
        URL_REGEX.test(part) ? <a target="_blank" href={getUrl(part)}>{part} </a> : part + " "
      );
  const isUserAdminForDelReview = () => {
    const delreviewAction = adminrights?.find(item => item.action === 'delreview');
    return delreviewAction && delreviewAction.admins.includes(accountname);
  };
  const deleteComment = async () => {
    //find the email of the verified user who left the review, so if Admin deletes it, we can email them.
    const userEmail = findUserEmail(comment?.user, userData)

    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "delreview",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                user: comment?.user,
                affiliate: affiliate,
                action_name: "delreview"
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        //if admin successfully deletes the review, send email to user.
        if(userType == "admin"){
          sendEmail(userEmail, "review_deleted")
        }
        sweetalert("Review deleted!")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }
  return (
    <div className="comment">
    <div className="comment-user"><b>{comment?.user}</b><div className="comment-time">{timeAgo(comment?.time_of_review)}</div></div>
    <div>{renderText(comment?.comment)}</div>
    <div className="delete-comment">
    {
        accountname === comment?.user || isUserAdminForDelReview() ? 
        <div onClick={() => deleteComment()}>Delete</div> 
        : 
        <></>
    }
    </div>
    </div>
  );
}

export default withUAL(Comment);
