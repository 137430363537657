import React, { useState } from 'react';
import '../App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Accordion from '../Universal/Accordion';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50vw",
  bgcolor: 'white',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  '@media (max-width: 768px)': {
    width: '80vw',
  }
};

const Verifyuser = ({openverifyuser, handleCloseverifyuser, accountname, activeUser, sweetalert, setTriggerFetch, triggerfetch}) => {

  const submitVerifyuser = async () => {
    let encrypted_email
    let email = formData["Email"]
    if(formData["Email"].trim() !== ""){
    try {
      const response = await fetch('https://eosden.herokuapp.com/encrypt', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
          });
    
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
    
        const data = await response.json();
        encrypted_email = data?.encryptedEmail
        console.log('Email sent successfully:', data);
      } catch (error) {
        console.error('Failed to send email:', error);
      }

    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "verify",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                user: accountname,
                socials: [
                  { key: "email", value: encrypted_email },
                  { key: "github", value: formData.Github },
                  { key: "twitter", value: formData.Twitter },
                  { key: "telegram", value: formData.Telegram },
                  { key: "kmsuite", value: formData.KMSuite }
                ],
                referrer: localStorage.getItem('referrerwax') || ""
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sweetalert("User verification sent successfully.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        handleCloseverifyuser()
      } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
    } else{
    sweetalert("Email is mandatory.")
    }
  }
  

  const handleBlur = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const [formData, setFormData] = useState({
    Email: '',
    Telegram: '',
    Twitter: '',
    Github: '',
    KMSuite: ''
  });
  
  return (
    <Modal
      open={openverifyuser}
      onClose={handleCloseverifyuser}
    >
      <Box sx={style}>
        <b style={{alignSelf:"flex-start"}}>Verify user</b>
        <Accordion trigger={"Read more"} text={"Please fill out your information in order to get verified. Write your handle name for each platform. No need for @ or the url. The email field is mandatory. \n\n Verified users will be eligible to earn rewards for rating and reviewing affiliate solutions.\n\n 1. Verification will be automatic if you have:\n\n a) EDEN election SBT\n\n  b) Pomelo Achievement badge\n\n 2. If you are not eligible for automatic verification, then EOSDEN core members will check the accounts of the platforms below and contact you via e-mail."} />
        <TextField onBlur={handleBlur} id="Email" label="Email - mandatory (will be encrypted)" variant="outlined" sx={{width:"100%"}} />
        <TextField onBlur={handleBlur} id="Telegram" label="Telegram" variant="outlined" sx={{width:"100%"}} />
        <TextField onBlur={handleBlur} id="Twitter" label="Twitter" variant="outlined" sx={{width:"100%"}} />
        <TextField onBlur={handleBlur} id="Github" label="Github" variant="outlined" sx={{width:"100%"}} />
        <TextField onBlur={handleBlur} id="KMSuite" label="KMSuite" variant="outlined" sx={{width:"100%"}} />
        <button className="modal-submit" onClick={()=> submitVerifyuser()}>Submit for review</button>
      </Box>
    </Modal>
  );
};

export default Verifyuser;