import React, {useEffect, useState} from 'react';
import '../App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30vw",
    bgcolor: 'white',
    borderRadius: "20px",
    boxShadow: 24,
    maxHeight:"90vh",
    overflow:"auto",
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "10px",
    '@media (max-width: 768px)': {
      width: '80vw',
    },
  };

const Quiz = ({quizopen, handleQuizclose, solution, quizData, accountname, sweetalert, activeUser}) => {
    const [showSummary, setShowSummary] = useState(false);
    const [transactionId, setTransactionId] = useState("");

    const [showOpeningPage, setShowOpeningPage] = useState(true);
    const [rewards, setRewards] = useState()
  
    const startQuiz = () => {
      setShowOpeningPage(false);
    };

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    // Initialize selectedAnswers as an array of arrays
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    useEffect(() => {
        if (quizData && quizData.questions) {
            setSelectedAnswers(quizData.questions.map(() => []));
        }
    }, [quizData]);
    
    const handleAnswerSelect = (questionIndex, optionIndex) => {
      setSelectedAnswers(prevSelectedAnswers => {
        const newAnswers = [...prevSelectedAnswers];
        const questionAnswers = new Set(newAnswers[questionIndex]);

        if (questionAnswers.has(optionIndex)) {
          questionAnswers.delete(optionIndex);
        } else {
          questionAnswers.add(optionIndex);
        }

        newAnswers[questionIndex] = Array.from(questionAnswers);
        return newAnswers;
      });
    };
  
    const goToNextQuestion = () => {
      if (currentQuestionIndex < quizData.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    };
  
    const goToPreviousQuestion = () => {
      if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    };
    
  

    useEffect(()=>{
        fetch(`https://wax.eosusa.io/v1/chain/get_table_rows`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              json: true,
              code: "nova3mindweb",
              table: "rewforquiz",
              scope: quizData?.affiliate,
            }),
          })
            .then((response) =>
              response.json().then((res) => {
                console.log(res?.rows[0]?.reward_amount)
                setRewards(res?.rows[0]?.reward_amount)
              })
            )
            .catch((e) => console.log(e));
    },[quizData])


    const handleSubmit = async() => {
        // Handle submission logic here
        const formattedAnswers = quizData.answers.map((options, questionIndex) => 
        options.map((_, optionIndex) => 
            selectedAnswers[questionIndex].includes(optionIndex) ? 1 : 0
        )
    );
        if (activeUser) {
            console.log({                    affiliate: quizData.affiliate,
                quiz_id: quizData.quiz_id,
                user_answers: formattedAnswers,
                user: accountname,})
          try {
            // fee and amount for user rewards are separate actions!!!!!!!!!!!!
            const transaction = {
              actions: [
                {
                  account: "nova3mindweb",
                  name: "submitansw",
                  authorization: [
                    {
                      actor: accountname, // use account that was logged in
                      permission: "active",
                    },
                  ],
                  data: {
                    affiliate: quizData.affiliate,
                    quiz_id: quizData.quiz_id,
                    user_answers: formattedAnswers,
                    user: accountname,
                  },
                }
              ],
            };
            await activeUser.signTransaction(transaction, {
              expireSeconds: 300,
              blocksBehind: 3,
              broadcast: true,
            });
  
            sweetalert("Quiz answers submitted!")
            setShowSummary(true); // Set the summary view to be displayed after successful submission
            setTimeout(() => {
                fetch("https://wax.greymass.com/v1/history/get_actions", {
                    method: 'POST',
                    "headers": {
                        "accept": "*/*",
                        "accept-language": "en-US,en;q=0.9,et-EE;q=0.8,et;q=0.7",
                        "content-type": "text/plain;charset=UTF-8",
                        "sec-fetch-dest": "empty",
                        "sec-fetch-mode": "cors",
                        "sec-fetch-site": "cross-site"
                      },
                    body: JSON.stringify({
                      account_name: accountname,
                      pos: -1, // Start from the most recent transaction
                      offset: -1, // Get the last 10 transactions
                    }),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      // Process the transaction history data here
                      if(data?.actions[0]?.action_trace?.act?.data?.memo == "Quiz reward"){
                            setTransactionId(data?.actions[0]?.action_trace?.trx_id)
                      }
                      else{setTransactionId("none")}
                    })
                    .catch((error) => {
                      console.error('Error querying transaction history:', error);
                    });
            }, 5000);
            } catch (error) {
            sweetalert(error.message)
          }
        } else {
          sweetalert("Please log in.")
        }
      };
      const renderSummaryView = () => {
        let correctQuestionCount = 0;

        // Check each question
        quizData.questions.forEach((_, qIndex) => {
            const totalCorrectAnswers = quizData.correct_answers[qIndex].reduce((acc, val) => acc + val, 0);
            const selectedCorrectAnswers = quizData.correct_answers[qIndex].reduce((acc, val, aIndex) => {
            return acc + (val === 1 && selectedAnswers[qIndex].includes(aIndex) ? 1 : 0);
            }, 0);
            
            const incorrectSelections = selectedAnswers[qIndex].some((aIndex) => quizData.correct_answers[qIndex][aIndex] === 0);

            // A question is completely right if all correct answers are selected and no incorrect option is selected
            if (selectedCorrectAnswers === totalCorrectAnswers && !incorrectSelections) {
            correctQuestionCount++;
            }
        });

        const scorePercentage = (correctQuestionCount / quizData.questions.length) * 100;

        return (
          <div className="quiz-summary">
            <div className="quiz-score">
                <b>Your Score: {scorePercentage.toFixed(2)}%</b>
                {scorePercentage.toFixed(2) >= quizData.correct_answers_perc ? <p>Congratulations! You passed the quiz and got rewarded!</p> : <p>Sorry, you did not pass the quiz.</p>}
                <p>{correctQuestionCount} out of {quizData.questions.length} questions were answered correctly.</p>
            </div>
            <div>
              See reward transaction: {
              transactionId === "none" || transactionId === "" 
                  ? "Not available."
                  : <a href={`https://wax.bloks.io/transaction/${transactionId}?tab=traces`} target="_blank">here</a>
              }
            </div>
            <hr className="solution-splitter"/>
            {quizData.questions.map((question, qIndex) => (
              <div key={qIndex} className="question-summary">
                <b>Question {qIndex + 1}: {question}</b>
                <div>
                  {quizData.answers[qIndex].map((answer, aIndex) => {
                    // Determine if the answer is correct
                    const isCorrect = quizData.correct_answers[qIndex][aIndex] === 1;
                    // Determine if the answer is selected by the user
                    const isSelected = selectedAnswers[qIndex].includes(aIndex);
                    // Set color based on correctness and selection
                    let answerColor;
                    if (isCorrect) {
                      answerColor = 'green'; // Correct answers are always green
                    } else if (isSelected && !isCorrect) {
                      answerColor = 'red'; // Incorrectly selected answers are red
                    } else {
                      answerColor = 'black'; // Default color
                    }
      
                    return (
                      <div 
                        key={aIndex} 
                        className="answer-summary"
                        style={{ color: answerColor }}
                      >
                        {answer} {isSelected ? "(Your choice)" : ""}
                      </div>
                    );
                  })}
                </div>
                <hr className="solution-splitter"/>
              </div>
            ))}
          </div>
        );
      };
      
      

  return (
    <>
    {quizData ?
    <Modal open={quizopen} onClose={handleQuizclose}>
    <Box sx={style}>
    {showOpeningPage ? (
          <div className="quiz-opening-page" style={{width:"100%"}}>
            <b>Welcome to the Quiz!</b>
            <p>Quiz rewards: {Number(rewards?.split(" ")[0]) / (Number(quizData?.max_users) - Number(quizData?.users_completed_quiz)) + " " + rewards?.split(" ")[1]}</p>
            <p>To get rewards, you need to get at least {quizData?.correct_answers_perc}% of the questions right.</p>
            <p>{quizData?.users_completed_quiz} people have taken the quiz. {quizData?.max_users - quizData?.users_completed_quiz == 1 ? quizData?.max_users - quizData?.users_completed_quiz + " person" : quizData?.max_users - quizData?.users_completed_quiz + " people"}  can still take the quiz.</p>
            <p>Quiz description: {quizData?.quiz_description}</p>
            <button onClick={startQuiz} className="quizbutton">Get Started</button>
          </div>
        ) : showSummary ? (
            renderSummaryView()
          ) : (
            <div className="quizarea">
            <div>
                            <b>Question {currentQuestionIndex + 1}: {quizData.questions[currentQuestionIndex]}</b>
                        </div>
                        {quizData.answers[currentQuestionIndex].map((option, index) => (
                            <div 
                                key={index} 
                                className="quizansweroption"
                                onClick={() => handleAnswerSelect(currentQuestionIndex, index)}
                                style={{cursor: "pointer"}}
                            >
                                {selectedAnswers[currentQuestionIndex]?.includes(index) ? 
                                <CheckBoxIcon 
                                sx={{
                                color: "#DD3333",
                                '&.Mui-checked': {
                                  color: "#DD3333",
                                }, }}
                                /> 
                                : 
                                <CheckBoxOutlineBlankIcon 
                                sx={{
                                    color: "#DD3333",
                                    '&.Mui-checked': {
                                      color: "#DD3333",
                                    }, }}
                                />} 
                                {option}
                            </div>
                        ))}
            <div className="quizbuttons">
            {currentQuestionIndex > 0 && (
                <button className="quizbutton" onClick={goToPreviousQuestion}>Previous question</button>
            )}
            {currentQuestionIndex < quizData?.questions?.length - 1 && (
                <button className="quizbutton" onClick={goToNextQuestion}>Next question</button>
            )}
            {currentQuestionIndex === quizData?.questions?.length - 1 && (
                <button className="quizbutton" onClick={handleSubmit}>Submit</button>
            )}
            </div>
            </div>        )}

    </Box>
    </Modal>
    :
    ""}
    </>
  );
};

export default Quiz;