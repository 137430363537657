import React, {useEffect, useState} from 'react';
import '../App.css';
import Accordion from '../Universal/Accordion'
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import Starratings from '../Universal/Starratings'
import { sendEmail } from '../Helpers/sendEmail';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Linkify from 'react-linkify';
import ShareIcon from '@mui/icons-material/Share';
import Quiz from './Quiz';

const SolutionCard = ({ logmein, setEditQuiz, adminrights, rewards, triggerfetch, setTriggerFetch, userType, affiliate, accountname, activeUser, sweetalert, setView, solution, total_ratings, nr_of_voters, ismysolution}) => {
  const get_code_type = (code_type_number) => {
    const code_types = [{type:"Open-source"}, {type:"Partly open-source"}, {type:"Closed source"}]
    return(code_types[code_type_number].type)
  }

  const isUserAdminForDelSolution = () => {
    const delreviewAction = adminrights?.find(item => item.action === 'delsolution');
    return delreviewAction && delreviewAction.admins.includes(accountname);
};

const [quizData, setQuiz] = useState(null)
const [quizopen, setQuizopen] = useState(false);
const [quizDone, setQuizDone] = useState(false);
const handleQuizopen = () => {
  if(quizDone){
    sweetalert("You have already taken this quiz!")
  }
  else{
    setQuizopen(true)

  }
}
const handleQuizclose = () => setQuizopen(false);

const endQuiz = async () => {
  if (activeUser) {
    try {
      const transaction = {
        actions: [
          {
            account: "nova3mindweb",
            name: "endquiz",
            authorization: [
              {
                actor: accountname, // use account that was logged in
                permission: "active",
              },
            ],
            data: {
              affiliate: affiliate,
              quiz_id: quizData.quiz_id,
            },
          },
        ],
      };
      await activeUser.signTransaction(transaction, {
        expireSeconds: 300,
        blocksBehind: 3,
        broadcast: true,
      });        
      //if admin successfully deletes the solution, send email to affiliate.
      setTimeout(() => {
        setTriggerFetch(!triggerfetch)
      }, 1000);
      sweetalert("Quiz ended!")
      } catch (error) {
      sweetalert(error.message)
    }
  } else {
    sweetalert("Please log in.")
  }
}
const [isWhitelisted, setIsWhitelisted] = useState(false)


useEffect(() => {
  if(accountname && solution){
    try {
      // URL to EOSIO node
      const url = `https://wax.eosusa.io/v1/chain/get_table_rows`;
      // Fetch data from EOSIO node
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "whitelist",
          scope: solution?.affiliate,
          limit:1000
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => response.json())
      .then(data => {
        console.log(data)
        data?.rows?.find(item => item.user == accountname) ? setIsWhitelisted(true) : setIsWhitelisted(false)
        const urlParams = new URLSearchParams(window.location.search);
        const quiz = urlParams.get('quiz');
        if(quiz === "true" && !quizopen){
          data?.rows?.find(item => item.user == accountname) ? handleQuizopen() : console.log("Not whitelisted")
        }

      })
    }
    catch(error){
      console.log(error)
    }
  }
},[accountname, solution])

useEffect(() => {
  const fetchData = async () => {
      console.log(solution)
      try {
          // URL to EOSIO node
          const url = `https://wax.eosusa.io/v1/chain/get_table_rows`;
          // Fetch data from EOSIO node
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              json: true,
              code: "nova3mindweb",
              table: "quiztb",
              scope: solution?.affiliate,
              limit:100
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          // If response is not ok, throw an error
          if (!response.ok) {
            throw Error('Network response was not ok' + response.statusText);
          }
  
          // Parse JSON data
          const data = await response.json();
          if (data.rows.length > 0 && data.rows[data.rows.length - 1].is_quiz_valid === 1) {
            setQuiz(data.rows[data.rows.length - 1]);
          }
        }
        catch(error){
          console.log(error)
        }
        try {
          // URL to EOSIO node
          const url = `https://wax.eosusa.io/v1/chain/get_table_rows`;
          // Fetch data from EOSIO node
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              json: true,
              code: "nova3mindweb",
              table: "quizcomplet",
              scope: solution?.affiliate,
              limit:1,
              lower_bound: accountname,
              upper_bound: accountname
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          // If response is not ok, throw an error
          if (!response.ok) {
            throw Error('Network response was not ok' + response.statusText);
          }
  
          // Parse JSON data
          const data = await response.json();
          if (data.rows.length > 0) {
            setQuizDone(true);
          }
        }
        catch(error){
          console.log(error)
        }

  }
  fetchData()

}, []);

const edit_quiz = () => {
  setView("createquiz")
  setEditQuiz(quizData)
}

const [loginModalOpen, setLoginModalOpen] = useState(false);
const handleCloseLogin = () => setLoginModalOpen(false);

useEffect(()=>{
  const urlParams = new URLSearchParams(window.location.search);
  const quiz = urlParams.get('quiz');
  if(!activeUser && quiz === "true" && !loginModalOpen){
    setTimeout(() => {
      setLoginModalOpen(true)
    }, 1000);
  }
},[activeUser])


  const [opengithub, setOpengithub] = useState(false);
  const handleOpengithub= () => setOpengithub(true);
  const handleClosegithub = () => setOpengithub(false);

  const [openadmin, setOpenadmin] = useState(false);
  const handleOpenadmin = () => setOpenadmin(true);
  const handleCloseadmin = () => setOpenadmin(false);

  const rateAffiliate = async (rating) => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "rate",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                user: accountname,
                affiliate:affiliate,
                rating: rating
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sweetalert("Rating successful.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  const copyUrl = () => {
    const url = "https://web3wax.eosde.net/community/" + solution.affiliate;
    
    navigator.clipboard.writeText(url)
      .then(() => {
        sweetalert("Solution link copied to clipboard!");
      })
      .catch(err => {
        // Maybe user denied clipboard permissions
        sweetalert("Failed to copy link!");
      });
  }

  const copyQuizUrl = () => {
    const url = "https://web3.eosde.net/community/" + solution.affiliate + "?quiz=true";
    
    navigator.clipboard.writeText(url)
      .then(() => {
        sweetalert("Solution link copied to clipboard!");
      })
      .catch(err => {
        // Maybe user denied clipboard permissions
        sweetalert("Failed to copy link!");
      });
  }

  function findEmail(data) {
    const socials = data.socials;
    if (socials) {
      const emailObj = socials.find((social) => social.key === "email");
      if (emailObj) {
        return emailObj.value;
      }
    }
    return null;
  }
      //function to get the email of the affiliate of this solution

    //get the affiliate's email
    const affiliateEmail = findEmail(solution)

  const collaborate = async () => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "collaborate",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                affiliate: accountname,
                collaborate_with: affiliate,
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sendEmail(affiliateEmail, "collaboration_offer")
        sweetalert("Collaboration offer sent!")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  const deleteSolution = async () => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "delsolution",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                affiliate: affiliate,
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });        
        //if admin successfully deletes the solution, send email to affiliate.
        if(userType == "admin"){
          sendEmail(affiliateEmail, "solution_deleted")
        }
        setView("feed")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  //ismysolution = true
  
  const getCodeTypeColor = (code_type) => {
    switch (code_type) {
      case "Open-source":
        return "#88DD33";
      case "Partly open-source":
        return "#c8b937";
      case "Closed source":
        return "DD3333";
      default:
        return "black";
    }
  };

  const iconMap = {
    email: EmailIcon,
    github: GitHubIcon,
    twitter: TwitterIcon,
    telegram: TelegramIcon
  };

  const handleRatingChange = (newRating) => {
    rateAffiliate(newRating)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30vw",
    bgcolor: 'white',
    borderRadius: "20px",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    '@media (max-width: 768px)': {
      width: '80vw',
    },
  };

  const styleadmin = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    maxWidth: "30vw",
    bgcolor: 'white',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    '@media (max-width: 768px)': {
      maxWidth: '80vw',
    },
  };

  const [verifReq, setVerifReq] = useState()

  useEffect(()  => {
    if(quizData && userType){
    console.log("solution")
    console.log(solution?.affiliate)
      if (solution?.affiliate) {
        fetch(`https://wax.eosusa.io/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "verifreq",
            scope: solution?.affiliate,
          }),
        })
          .then((response) =>
            response.json().then((res) => {
              console.log(res?.rows)
              console.log(quizData)
              //if last of res rows quiz id is ame as quizdata quiz id
              if(res?.rows[res?.rows?.length - 1]?.quiz_id == quizData?.quiz_id && res?.rows[res?.rows?.length - 1]?.verif_requirement == 1){
                setVerifReq(true)
                console.log("Verif req is true!")
                const urlParams = new URLSearchParams(window.location.search);
                const quiz = urlParams.get('quiz');
                if(quiz === "true"  && !ismysolution && !quizopen){
                  if(activeUser && userType && quizData){
                    if(userType=="unverified" ){
                      console.log("No quiz.")
                    }
                    else{
                      handleQuizopen()
                      console.log("Can take quiz!")
                    }
                  }
                }
              }
              else{
                setVerifReq(false)
                if(userType){
                  const urlParams = new URLSearchParams(window.location.search);
                  const quiz = urlParams.get('quiz');
                  if(quiz == "true"){
                    handleQuizopen()
                  }
                }
                console.log("Verif req is false!")

              }
            })
          )
          .catch((e) => console.log(e));
      }
    }
  }, [solution, quizData, userType])

  const renderGithubButton = () => {
    const validLinks = solution?.links_to_code?.filter(link => link !== '') || [];
    console.log(validLinks)
    if(validLinks.length > 1) {
      return (
        <button className="open-github" onClick={() => handleOpengithub()}>Open github</button>
      );
    } else if(validLinks.length === 1) {
      const url = validLinks[0].startsWith('http://') || validLinks[0].startsWith('https://') || validLinks[0].startsWith('ftp://')? 
        validLinks[0] : 'https://' + validLinks[0];
      return (
        <a href={url} target="_blank" rel="noreferrer"><button className="open-github">Open github</button></a>
      );
    } else {
      return null;
    }
  };

  const renderWebsiteButton = () => {
    const validlink = solution?.website
    const url = validlink.startsWith('http://') || validlink.startsWith('https://') || validlink.startsWith('ftp://') ? validlink : 'https://' + validlink;
    if(solution?.website && solution?.website.trim() !== ''){
      return (
        <a href={url} target="_blank" rel="noreferrer" class="website-button"><button className="card-source" style={{width:"100%"}}>Website</button></a>
      );
    }
    else{
      return
    }
  }
  
  const URL_REGEX = /(https?:\/\/|ftp:\/\/|www\.)[^\s]+(?:\.[^\s]+)*(?!\.)(?:\/[^\s]*)?/g;
  const getUrl = (part) => { 
  return(part.startsWith('http://') || part.startsWith('https://') || part.startsWith('ftp://')? 
  part : 'https://' + part)
  }
  const renderText = txt =>
    txt
      .split(" ")
      .map(part =>
        URL_REGEX.test(part) ? <a target="_blank" href={getUrl(part)}>{part} </a> : part + " "
      );


  

  return (
    <div className="card">
      <Quiz quizopen={quizopen}
        handleQuizopen={handleQuizopen}
        handleQuizclose={handleQuizclose}
        affiliate={affiliate}
        accountname={accountname}
        activeUser={activeUser}
        sweetalert={sweetalert}
        setTriggerFetch={setTriggerFetch}
        triggerfetch={triggerfetch}
        rewards={rewards}
        solution={solution}
        quizData = {quizData}      
      />
            <Modal
        open={loginModalOpen}
        onClose={handleCloseLogin}
      >
        <Box sx={style}>
        <div style={{fontSize:"18px", fontWeight:"500", textAlign:"center"}}>{solution.solution_name} quiz has been shared with you!</div>
        <div style={{marginBottom:"10px"}}>Please log in to take the quiz.</div>
        <button className="card-source" onClick={()=>{logmein()}}>Log in</button>
        </Box>
      </Modal>
                <Modal
            open={openadmin}
            onClose={handleCloseadmin}
          >
            <Box sx={styleadmin}>
            {
              isUserAdminForDelSolution() ? 
              <button className="card-source" onClick={() => deleteSolution()}>Delete solution</button> 
              : 
              <></>
            }
            {ismysolution && quizData?.is_quiz_valid == 1 ?
          <>
            <button className="card-source card-source-blue" onClick={() => copyQuizUrl()}>Copy Quiz Link</button>
            <button className="card-source card-source-blue" onClick={() => edit_quiz()}>Edit Quiz</button>
            <button className="card-source card-source-blue" onClick={() => endQuiz()}>End Quiz</button>
          </>
            :
            <></>
          }
          {(ismysolution && quizData?.is_quiz_valid == 0) || !quizData ?
          <>
            <button className="card-source card-source-blue" onClick={() => setView("createquiz")}>Create quiz</button>
          </>
            :
            <></>
          }
          {ismysolution ?
            <button className="card-source" onClick={()=>setView("addsolution")}>Modify solution</button>
            :
            <></>
          }
            </Box>
          </Modal>
          <Modal
            open={opengithub}
            onClose={handleClosegithub}
          >
            <Box sx={style}>
              <b style={{alignSelf:"flex-start"}}>Github links</b>
              {solution?.links_to_code?.map((value, index) => {
                if(value.length > 0){
                  const formattedUrl = value.startsWith('http://') || value.startsWith('https://') ? value : `https://${value}`;
                  return (
                      <a href={formattedUrl} target="_blank" style={{alignSelf:"flex-start", width:"100%"}} key={index}>
                          <button className="open-github" style={{width:"100%"}}>{value}</button>
                      </a>
                  );
                }
              })}
            </Box>
          </Modal>
          <div class="share" onClick={()=> copyUrl()}>Share solution <br/> Copy link</div>
      <div className="card-top">
        <div className="card-logo-name-category">
          <img src={solution?.logo_url}
            onError={(e)=>{
              if (!e.target.hasError) {
                e.target.onerror = null; 
                e.target.src="placeholder.png";
                e.target.hasError = true;
              }
            }} 
          alt="Logo" className="card-logo" />
          <div className="card-name-category">
            <div className="card-name">{solution.solution_name}</div>
            <div className="card-category">{solution.category}</div>
          </div>
        </div>
        <div className="card-buttons">
        {
              isUserAdminForDelSolution() ? 
              <button className="card-source" onClick={() => deleteSolution()}>Delete solution</button> 
              : 
              <></>
            }
          {userType == "affiliate" && !ismysolution?
            <button className="card-source card-source-blue" onClick={()=>collaborate()}>Collaborate <br/><span style={{fontSize:"10px"}}>(+{rewards?.collab_reward} NCO)</span></button>
            :
            <></>
          }
          {isWhitelisted && quizData ? 
            <button className="card-source card-source-blue" onClick={()=>handleQuizopen()}>Take quiz</button>
            :
            <>
            {accountname && quizData && !ismysolution ?
              <button disabled={verifReq && userType=="unverified"} className="card-source card-source-blue" style={{background:verifReq && userType=="unverified" ? "gray" : null, border:verifReq && userType=="unverified" ? "gray" : null}} onClick={handleQuizopen}>{verifReq && userType=="unverified" ? <span>Get verified for quiz</span> : <span>Take quiz</span>}</button>
              :
              <></>
            }
            </>
          }
          
          {ismysolution ?
            <button className="card-source" onClick={()=>handleOpenadmin()}>Admin</button>
            :
            <></>  
        }
          <button
            className="card-source-outline"
            style={{
              borderColor: getCodeTypeColor(get_code_type(solution?.code_type)),
              color: getCodeTypeColor(get_code_type(solution?.code_type))
            }}
          >
            {get_code_type(solution?.code_type)}
          </button>
          {renderGithubButton()}
          {renderWebsiteButton()}
        </div>
      </div>
      <Starratings rewards={rewards} rating={Number(total_ratings)/Number(nr_of_voters)} onChange={handleRatingChange}/>

      <div className="card-description">{renderText(solution?.description)}</div>
      <div className="card-bottom">
        
      {solution.socials.map(item => {
  const Icon = iconMap[item.key];
  const isUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(item.value);

  let hrefValue = item.value;
  if (!isUrl) {
    switch(item.key) {
      case 'telegram':
        hrefValue = `https://t.me/${item.value}`;
        break;
      case 'twitter':
        hrefValue = `https://twitter.com/${item.value}`;
        break;
      case 'github':
        hrefValue = `https://github.com/${item.value}`;
        break;
      case 'email':
        hrefValue = `mailto:${item.value}`;
        break;
      default:
        break;
    }
  }

  if (Icon && item?.value?.trim()?.length > 0) {
    return (
      <a href={hrefValue} className="social-text social" target="_blank" rel="noopener noreferrer" key={item.key}>
        <Icon style={{ color: "#575757 " }}/>
        {item.key === 'github' ? (
          <span className="social-text">{item.value.split('/').pop()}</span>
        ) : 
        <span>{item.value}</span>
        }
      </a>
    );
  }
  return null;
})}

      </div>
    </div>
  );
};

export default SolutionCard;