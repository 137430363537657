import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import Collaborationcard from './Collaborationcard';
import Accordion from '../Universal/Accordion';

function Collaborations({rewards, triggerfetch, setTriggerFetch, endpoint, accountname, solutions, sweetalert, activeUser, setView, setSolutionid}) {
  const [collab, setCollab] = useState([])
  useEffect(()=>{
    const getCollaborations = async () => {
    console.log(accountname)
    let collaborations;
    await fetch(`${endpoint}/v1/chain/get_table_rows`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        json: true,
        code: "nova3mindweb",
        table: "collab",
        scope: accountname,
      }),
    })
      .then((response) =>
        response.json().then((res) => {
          setCollab(res?.rows)
          collaborations = res?.rows
        })
      )
      .catch((e) => console.log(e));

      const filteredSolutions = solutions
        .map((solution, index) => ({ ...solution, originalIndex: index }))
        .filter((solution) => {
          return collaborations.some(
            (collaboration) => solution.affiliate === collaboration.collaborate_with
          );
        })
        .map((solution) => {
          const collaboration = collaborations.find(
            (collaboration) => solution.affiliate === collaboration.collaborate_with
          );
          return {
            ...solution,
            accepted: collaboration.accepted,
            accepted_by_both: collaboration.accepted_by_both,
          };
        });
    
      setCollab(filteredSolutions)
    }
    getCollaborations()
  },[activeUser, triggerfetch])

  return (
    <div className="App">
      <div style={{marginTop:"20px"}} className="collaborationsview">
      <b>My collaborations</b>
      <Accordion trigger={"Read about collaborations..."} text={"Collaborate: This action enables an affiliate to offer collaboration to another affiliate. \n\n The affiliate is signaling their willingness to collaborate with another EOSDEN affiliate. If a collaboration offer is accepted, the two affiliates are rewarded in NCO token. \n\n Endcollab: This action enables both affiliates to end the collaboration. \n\n An affiliate is signaling to the other that they are no longer interested in collaborating.\n\n Once the action is executed, the “collaborate_with” solution will not appear in the affiliate’s collaborations list"}/>
      {collab.length > 0 ? collab?.map((item, index) => {
      return(<Collaborationcard rewards={rewards} triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} key={index} solutions={solutions} setSolutionid={setSolutionid} collaborationkey={index} {...item} accountname={accountname} activeUser={activeUser} sweetalert={sweetalert} setView={setView} setSolutionid={setSolutionid} collaborations={collab}/>)
    })
    :
    <div>You have no collaborations.</div>}
    </div>
    </div>
  );
}

export default withUAL(Collaborations);
