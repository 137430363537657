import './Components/App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import Navbar from './Components/Navigation/Navbar'
import sweetalert from "./Components/Universal/sweetalert";
import Feed from './Components/Feed/Feed'
import Solution from './Components/Solution/Solution'
import Error from './Components/Errors/Error'
import Verifyusers from "./Components/Admin/Verifyusers";
import Verifiedusers from "./Components/Admin/Verifiedusers";
import Proposedsolutions from "./Components/Admin/Proposedsolutions";
import Collaborations from './Components/Affiliate/Collaborations';
import Addsolution from './Components/Unverified/Addsolution';
import BackButton from './Components/Universal/backButton';
import Firsttime from './Components/Universal/Firsttime';
import Createquiz from './Components/Solution/Createquiz';
/* global BigInt */

//APP JS IS THE PARENT COMPONENT FOR EVERYTHING ELSE. THIS IS WHERE WE SET VIEWS AND FETCH SHARED DATA.
function App(props) {
  const {
    ual: { showModal, hideModal, activeUser, login, logout },
  } = props;

  // Extract category from the URL or default to "All categories"
  const initialCategory = (() => {
    const pathSegments = window.location.pathname.split('/');
    const filterIndex = pathSegments.indexOf('category');
    if (filterIndex !== -1 && pathSegments[filterIndex + 1]) {
        return decodeURIComponent(pathSegments[filterIndex + 1]);
    }
    return "All categories";
  })();


  //MAIN STATES
  const [solutions, setSolutions] = useState()
  const [accountname, setAccountname] = useState("");
  const [category, setCategory] = useState(initialCategory);
  const [view, setView] = useState("feed");
  const [solutionid, setSolutionid] = useState();
  const [userType, setUserType] = useState(null);
  const [adminType, setAdmintype] = useState(null);
  const [adminrights, setAdminrights] = useState();
  const [editQuiz, setEditQuiz] = useState()


  const [triggerfetch, setTriggerFetch] = useState(false);
  const [viewHistory, setViewHistory] = useState([]);
  const [userData, setUserdata] = useState();
  const [rewards, setRewards] = useState({})
  const [categories, setCategories] = useState()
  const [balance, setBalance] = useState("0.0000 NCO")

  const [openfirsttime, setOpenfirsttime] = useState(false);
  const handleOpenfirsttime= () => setOpenfirsttime(true);
  const handleClosefirsttime = () => setOpenfirsttime(false);

  const [openaddsolution, setOpenaddsolution] = useState(false);
  const handleOpenaddsolution = () => setOpenaddsolution(true);
  const handleCloseaddsolution = () => setOpenaddsolution(false);

  const [openverifyuser, setOpenverifyuser] = useState(false);
  const handleOpenverifyuser= () => setOpenverifyuser(true);
  const handleCloseverifyuser = () => setOpenverifyuser(false);

  const [openaffiliate, setOpenaffiliate] = useState(false);
  const handleOpenaffiliate= () => setOpenaffiliate(true);
  const handleCloseaffiliate = () => setOpenaffiliate(false);

  const updateView = (newView) => {
    setViewHistory((prevHistory) => [...prevHistory, view]);
    setView(newView);
  };

  //ENDPOINT THAT'S USED FOR FETCHING ACROSS THE APP
  const endpoint ="https://wax.eosusa.io"
  
  //Helper for testing. Put setusertypefromconsole("admin") in console.
  const setusertypefromconsole = (type) =>{
    setUserType(type)
  }

  window.setusertypefromconsole = setusertypefromconsole;
  
  useEffect(()=>{
    // Check if the URL contains the /filter/ segment
    if (window.location.pathname.includes('/category/') && view !== 'feed') {
      // Set the URL back to the base path
      const baseURL = window.location.origin;
      window.history.pushState({}, '', baseURL);
  }
  },[view])


  useEffect(() => {
    const referrer = extractAccountFromURL(window.location.href);
  
    if (referrer) {
      localStorage.setItem('referrerwax', referrer);
    }
    //GETS ALL THE USER DATA, REWARDS ETC.
    const getMaindata = async () => {
      let usdeos;
      let ncoeos;

      await fetch(`${endpoint}/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "swap.alcor",
          table: "pools",
          scope: "swap.alcor",
          limit:1,
          lower_bound:1132,
          upper_bound:1132
        }),
      })
        .then((response) =>
          response.json().then((res) => {
            ncoeos = parseFloat(res?.rows[0]?.tokenA?.quantity.split(" ")[0]) / parseFloat(res?.rows[0]?.tokenB?.quantity.split(" ")[0]);
            console.log(`The price of NCO is: ${ncoeos}`);     
          })
        )
        .catch((e) => console.log(e));

      await fetch(`${endpoint}/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "ncorewards",
          scope: "nova3mindweb",
          limit:100
        }),
      })
        .then((response) =>
          response.json().then((res) => {
            console.log("rewards?.rate_reward");
            console.log(res);
            
            // 1. Convert `res?.rows` to the rewards object.
            let rewards = {};
            
            res.rows.forEach(item => {
              switch(item.reward_type) {
                  case 'rate':
                      rewards['rate_reward'] = item.reward_amount;
                      break;
                  case 'review':
                      rewards['review_reward'] = item.reward_amount;
                      break;
                  case 'collab':
                      rewards['collab_reward'] = item.reward_amount;
                      break;
                  case 'addsol':
                      rewards['solution_reward'] = item.reward_amount;
                      break;
                  case 'solref':
                      rewards['solution_referral_reward'] = item.reward_amount;
                      break;
                  case 'userref':
                      rewards['user_referral_reward'] = item.reward_amount;
                      break;
                  case 'userverif':
                      rewards['verification_reward'] = item.reward_amount;
                      break;
                  // Add more cases if needed...
              }
          });
            
            // Your conversion function remains the same.
            const convertToNCO = (usdCents) => {
                const usd = usdCents / 100;
                const nco = usd * ncoeos;  // Assuming ncoeos is defined somewhere else in your code.
                return Math.round(nco);
            };
            
            // 2. Convert the amounts.
            const rewardsInNCO = {};
            
            for (let key in rewards) {
                rewardsInNCO[key] = convertToNCO(rewards[key]);
            }
            
            console.log(rewardsInNCO);
            setRewards(rewardsInNCO);
          })
        )
        .catch((e) => console.log(e));

        await fetch(`${endpoint}/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "categoria",
            scope: "nova3mindweb",
            limit:1
          }),
        })
          .then((response) =>
            response.json().then((res) => {
              res?.rows[0]?.names.unshift("All categories")
              setCategories(res?.rows[0]?.names)
              if(window.location.pathname.includes('/addsolution/')){
                setView("addsolution")
              }
            })
          )
          .catch((e) => console.log(e));
      if(activeUser){
      let accountname = await activeUser.getAccountName()
      let usertypetracker="";
      await fetch(`${endpoint}/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "verifieduser",
          scope: "nova3mindweb",
          lower_bound: accountname,
          upper_bound: accountname
        }),
      })
        .then((response) =>
          response.json().then(async(res) => {
            if(res?.rows[0]?.user == accountname){
              usertypetracker = "verified"
            }
            else{
              console.log(res?.rows[0]?.user)
              console.log("Not verified.")
            }
          })
        )
        .catch((e) => console.log(e));

        await fetch(`${endpoint}/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "accounts",
            scope: accountname
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res && res.rows && res.rows.length > 0 && res.rows[0].balance) {
                  setBalance(res.rows[0].balance);
                } else {
                  throw new Error("Balance not found in response");
                }
              });
            } else {
              throw new Error("Request failed");
            }
          })
          .catch((e) => console.log(e));
        
        

        await fetch(`${endpoint}/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "verifieduser",
            scope: "nova3mindweb",
            limit:200
          }),
        })
          .then((response) =>
            response.json().then((res) => {
              setUserdata(res?.rows) //maybe need to also add affiliates to userdata?
            })
          )
          .catch((e) => console.log(e));

          await fetch(`${endpoint}/v1/chain/get_table_rows`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              json: true,
              code: "nova3mindweb",
              table: "adminrights",
              scope: "nova3mindweb",
              limit:1000
            }),
          })
            .then((response) =>
              response.json().then(async(res) => {
                console.log("ADMINSTUFF")
                console.log(res)
                setAdminrights(res?.rows)
              })
            )
            .catch((e) => console.log(e));
      

      await fetch(`${endpoint}/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "solutiont",
          scope: "nova3mindweb",
          lower_bound: accountname,
          upper_bound: accountname
        }),
      })
        .then((response) =>
          response.json().then(async(res) => {
            if(res?.rows[0]?.affiliate == accountname){
            usertypetracker = "affiliate"
            }
            else{
              console.log("Not an affiliate.")
            }
          })
        )
        .catch((e) => console.log(e));
      if(accountname == "lennyaccount" || accountname == "ncoltd123451" || accountname == "vladislav.x"){
        usertypetracker = "admin"
      }
      if(usertypetracker == ""){
        setUserType("unverified")
        const hasvisited = localStorage.getItem("hasvisited")
        if(!hasvisited){
          handleOpenfirsttime()
        }
      }
      else{
        setUserType(usertypetracker)
      }
    }
    else{
      console.log("Not logged in.")
    }
    };
    const getUser = async () => {
      if (activeUser) {
        const accountName = activeUser.getAccountName();
        accountName.then(function (result) {
          setAccountname(result);
        });
      } else {
        setAccountname("");
      }
    };

    //FETCH FOR SOLUTIONS TABLE.
    const fetchSolutions = async () => {
      await fetch(`${endpoint}/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "solutiont",
          scope: "nova3mindweb",
          limit:100
        }),
      })
        .then((response) =>
          response.json().then((res) => {
            console.log(res)
            const pathName = window.location.pathname;
            const communityPath = '/community/';
            
            if(pathName.includes(communityPath)) {
              const id = pathName.split(communityPath)[1];
              console.log("ID: " + id)
              let filteredSolutions = res.rows.filter(obj => obj.affiliate.toLowerCase() == id.toLowerCase());
              console.log(filteredSolutions)
              setSolutions(filteredSolutions)
              setSolutionid(0)
              setView("solution")
            }
            else{
              let unsorted = res?.rows
              const sortedData = unsorted.sort((a, b) => {
                const avgRatingA = a.nr_of_voters !== 0 ? parseFloat(Number(a.total_ratings) / Number(a.nr_of_voters)) : 0;
                const avgRatingB = b.nr_of_voters !== 0 ? parseFloat(Number(b.total_ratings) / Number(b.nr_of_voters)) : 0;
                return avgRatingB - avgRatingA;
              });
              setSolutions(sortedData)
            }

          })
        )
        .catch((e) => console.log(e));
    };
    getUser();
    fetchSolutions();
    getMaindata()
  }, [activeUser, triggerfetch]);

  //LOGIN. activeUser forces refetching.
  const logmein = async () => {
    await showModal()
  }

  const extractAccountFromURL = (url) => {
    const affiliatePattern = /\/affiliate\/([^\/]+)/;
    const matches = url.match(affiliatePattern);
    return matches && matches[1];
};

  //LOGOUT. RESETS EVERYTHING, DOES A REFETCH.
  const logmeout = () => {
    logout();
    setAccountname("");
    setUserType(null)
    setTimeout(() => {
      setTriggerFetch(!triggerfetch)
    }, 1000);
  };

  //COMPONENTS FOR MAIN VIEWS. CONTROLLED BY view/setView.
  return (
    <>
    <Firsttime setView={setView} handleOpenaddsolution={handleOpenaddsolution} setOpenverifyuser={setOpenverifyuser} openverifyuser={openverifyuser} handleCloseverifyuser={handleCloseverifyuser} handleCloseverifyuser={handleCloseverifyuser} openaddsolution={openaddsolution} setOpenaddsolution={setOpenaddsolution} handleOpenverifyuser={handleOpenverifyuser} handleCloseaddsolution={handleCloseaddsolution} handleOpenfirsttime={handleOpenfirsttime} handleClosefirsttime={handleClosefirsttime} openfirsttime={openfirsttime}/>
    {accountname ? <div className="balance"><div style={{fontSize:"12px"}}>My balance</div> <div style={{fontSize:"14px"}}><b>{balance}</b></div></div> : <></>}
    <Navbar rewards={rewards} setOpenverifyuser={setOpenverifyuser} openverifyuser={openverifyuser} handleCloseverifyuser={handleCloseverifyuser} handleCloseverifyuser={handleCloseverifyuser} openaddsolution={openaddsolution} setOpenaddsolution={setOpenaddsolution} handleOpenverifyuser={handleOpenverifyuser} handleCloseaddsolution={handleCloseaddsolution} balance={balance} triggerfetch={triggerfetch} setTriggerFetch={setTriggerFetch} setCategory={setCategory} logmein={logmein} logmeout={logmeout} accountname={accountname} activeUser={activeUser} userType={userType} setView={updateView} solutions={solutions} setSolutionid={setSolutionid} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>
    <BackButton viewHistory = {viewHistory} setView = {setView} setViewHistory={setViewHistory}/>
    {view === 'feed' ? (
      <Feed triggerfetch={triggerfetch} setTriggerFetch={setTriggerFetch} view={view} rewards={rewards} categories = {categories} showModal={showModal} hideModal={hideModal} activeUser={activeUser} login={login} logout={logout} sweetalert={sweetalert} setView={updateView} setSolutionid={setSolutionid} setCategory={setCategory} category={category} solutions={solutions} accountname={accountname} setAccountname={setAccountname}/>
    ) : view === 'solution' ? (
      <Solution setEditQuiz={setEditQuiz} adminrights={adminrights} endpoint={endpoint} setSolutions={setSolutions} setSolutionid={setSolutionid} rewards ={rewards} userData={userData} userType={userType} setView={updateView} triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} solutions={solutions} solutionid={solutionid} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname}/>
    ) : view === 'verifyusers' ? (
      <Verifyusers triggerfetch={triggerfetch} setTriggerFetch={setTriggerFetch} triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} solutions={solutions} accountname={accountname} solutionid={solutionid} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname} endpoint={endpoint}/>
    ) : view === 'verifiedusers' ? (
      <Verifiedusers solutions={solutions} solutionid={solutionid} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname} endpoint={endpoint}/>
    ) : view === 'proposedsolutions' ? (
      <Proposedsolutions triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} solutions={solutions} solutionid={solutionid} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname} endpoint={endpoint}/>
    ) : view === 'collaborations' ? (
      <Collaborations rewards={rewards} triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} solutions={solutions} solutionid={solutionid} setSolutionid={setSolutionid} setView={updateView} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname} endpoint={endpoint}/>
    ) : view === 'addsolution' ? (
      <Addsolution rewards={rewards} categoriesprop={categories} solutions={solutions} setTriggerFetch = {setTriggerFetch} solutionid={solutionid} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname}/>
    ) : view === 'createquiz' ? (
      <Createquiz endpoint={endpoint} editQuiz={editQuiz} setEditQuiz={setEditQuiz} sweetalert={sweetalert} activeUser={activeUser} accountname={accountname} setTriggerFetch={setTriggerFetch}/>
    )
    : (
      <Error />
    )}
    </>
  );
}

export default withUAL(App);
