import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import SolutionCard from './SolutionCard';
import SubmitComment from './SubmitComment';
import Comment from './Comment';
import Accordion from '../Universal/Accordion';

function Solution({logmein, setEditQuiz, adminrights, endpoint, setSolutions, rewards, setView, triggerfetch, setTriggerFetch, sweetalert, activeUser, accountname, solutions, solutionid, setSolutionid, userType, userData}) {
  const [comments, setComments] = useState()
  const [ismysolution, setIsmysolution] = useState()

  useEffect(()=>{    
    const getReward = async () => {
    };
    const getComments = async () => {
      await fetch(`${endpoint}/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "userstable",
          scope: solutions[solutionid].affiliate,
          limit:200
        }),
      })
        .then((response) =>
          response.json().then((res) => {
            console.log(res)
            console.log(solutions[solutionid].affiliate)
            const sortedComments = res?.rows.sort((a, b) => {
              return new Date(b.time_of_review) - new Date(a.time_of_review);
            });
            setComments(sortedComments);
          })
        )
        .catch((e) => console.log(e));
    };
    getComments()
    getReward()
    if(solutions[solutionid]?.affiliate == accountname){
      setIsmysolution(true)
    }
    },[triggerfetch])
    

  return (
    <div className="App">
    <SolutionCard logmein={logmein} setEditQuiz={setEditQuiz} adminrights={adminrights} rewards={rewards} triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} userType={userType} setView={setView} solution={solutions[solutionid]} accountname={accountname} activeUser={activeUser} sweetalert={sweetalert} total_ratings={solutions[solutionid].total_ratings} nr_of_voters={solutions[solutionid].nr_of_voters} affiliate={solutions[solutionid].affiliate} ismysolution={ismysolution}/>
    <SubmitComment rewards ={rewards} triggerfetch = {triggerfetch} setTriggerFetch = {setTriggerFetch} sweetalert={sweetalert} accountname={accountname} activeUser={activeUser} affiliate={solutions[solutionid].affiliate}/>
    <div style={{marginLeft:"10px"}} className="accordion"><Accordion trigger={"Read about reviews..."} text={"This action enables a user to leave a review of an affiliate/solution and be rewarded in NCO token. Only a verified user can leave a review."}/></div>
    {comments?.map((value, index)=>{
      if(value.comment !== "" && value.comment !== "empty"){
        return(<Comment adminrights={adminrights} userData={userData} userType={userType} key={index} setTriggerFetch={setTriggerFetch} triggerfetch={triggerfetch} sweetalert={sweetalert} comment={value} accountname={accountname} affiliate={solutions[solutionid].affiliate} activeUser={activeUser} />)
      }
    })
    }
    </div>
  );
}

export default withUAL(Solution);
