import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";

function Error() {
  return (
    <div className="App">
    Sorry something went wrong.
    </div>
  );
}

export default withUAL(Error);
