import React, { useState } from 'react';
import '../App.css';
import Collapsible from 'react-collapsible';

//Accordion component. Takes in trigger (the short display text) and the long text that's in the accordion.
function Accordion({trigger, text}) {

  const paragraphs = text?.split('\n');

  return (
    <Collapsible 
      trigger= {trigger}
      triggerStyle={{ color: "white", backgroundColor:"#DD3333", fontSize:"12px", alignSelf:"flex-start", border:"1px solid #DD3333", padding:"2px 7px 3px 7px", borderRadius:"10px", cursor:"pointer"}}
      transitionTime = "100"
    >
      <div className="accordion-text">
      {paragraphs?.map((paragraph, index) => (
        <p key={index} className="paragraph">{paragraph}</p>
      ))}
      </div>
    </Collapsible>
  );
}

export default Accordion;
