import React, { useState, useEffect } from 'react';
import '../App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FileUpload from '../Helpers/Fileupload';
import Accordion from '../Universal/Accordion';

  const code_types = [
    {
      value: 0,
      label: 'Open Source',
    },
    {
      value: 1,
      label: 'Partly Open Source',
    },
    {
      value: 2,
      label: 'Closed Source',
    }
  ];

  const affiliateTypes = [
    { value: 0, label: "Content Creator" },
    { value: 1, label: "Networker/Advertiser" },
    { value: 2, label: "Web3 Solution Provider" },
  ];

const Addsolution = ({rewards, activeUser, accountname, sweetalert, triggerfetch, setTriggerFetch, solutions, categoriesprop}) => {
  let categories = [...categoriesprop]
  categories.shift()

  const [formData, setFormData] = useState({
    affiliate: accountname || '',
    affiliateName: '',
    affiliateType: '',
    description: '',
    solutionName: '',
    socials: '',
    contracts: [''],
    linksToCode: [''],
    affiliatesRole: '',
    affiliateEmail: '',
    logo_url: '',
    website: ''
  });
  const [codeType, setCodeType] = useState(code_types[0].value || '');
  const [category, setCategory] = useState(categories[0].value || '');
  const [logourl, setLogourl] = useState("");
  const [isaffiliate, setIsaffiliate] = useState(false);
  const [affiliatetype, setAffiliateType] = useState(affiliateTypes[0].value || '');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split('/');
    // Check if the URL is structured like /addsolution/category
    if (pathSegments.length === 3 && pathSegments[1] === 'addsolution') {
      const categoryValue = decodeURIComponent(pathSegments[2]); // Decode the category value
      setCategory(categoryValue);
      setIsDisabled(true);
    }
  }, []);

  useEffect(() => {
    const matchingIndex = solutions?.findIndex(
      (solution) => solution.affiliate === accountname
    );
  
    if (matchingIndex >= 0) {
      setIsaffiliate(true)
  
      const twitterObject = solutions[matchingIndex]?.socials?.find(
        (social) => social.key === "twitter"
      );
      const githubObject = solutions[matchingIndex]?.socials?.find(
        (social) => social.key === "github"
      );
      const telegramObject = solutions[matchingIndex]?.socials?.find(
        (social) => social.key === "telegram"
      );
      const emailObject = solutions[matchingIndex]?.socials?.find(
        (social) => social.key === "email"
      );
  
      setFormData({
        adder: accountname || '',
        affiliate: solutions[matchingIndex]?.affiliate || '',
        affiliateName: solutions[matchingIndex]?.affiliate || '',
        affiliateType: solutions[matchingIndex]?.affiliate_type,
        description: solutions[matchingIndex]?.description || '',
        solutionName: solutions[matchingIndex]?.solution_name || '',
        twitter: twitterObject?.value || "",
        github: githubObject?.value || "",
        telegram: telegramObject?.value || "",
        affiliateEmail: emailObject?.value || "",
        contracts: solutions[matchingIndex]?.contracts || [''],
        linksToCode: solutions[matchingIndex]?.links_to_code || [''],
        affiliatesRole: solutions[matchingIndex]?.affiliates_role || '',
        logo_url: solutions[matchingIndex]?.logo_url || '',
        website: solutions[matchingIndex]?.website || ''
      });
      setCategory(solutions[matchingIndex]?.category);
      setCodeType(solutions[matchingIndex]?.code_type);
      setLogourl(solutions[matchingIndex]?.logo_url)
      setAffiliateType(solutions[matchingIndex]?.affiliate_type);
    }
  }, [activeUser]);
  


  const handleBlur = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };
  
  const handleChangecodetype = (e) => {
    setCodeType(e.target.value);
  };
  

  const handleChangeaffiliatetype = (e) => {
    setAffiliateType(e.target.value);
  };
  const handleChangecategory = (e) => {
    console.log(e.target.value)
    setCategory(e.target.value);
  };

  const handleLinksToCodeChange = (index, newValue) => {
    const newLinksToCode = [...formData.linksToCode];
    newLinksToCode[index] = newValue;
    setFormData({ ...formData, linksToCode: newLinksToCode });
  };

  const addLinksToCodeField = () => {
    setFormData({ ...formData, linksToCode: [...formData.linksToCode, ''] });
  };

  const handleContractChange = (index, newValue) => {
    const newContracts = [...formData.contracts];
    newContracts[index] = newValue;
    setFormData({ ...formData, contracts: newContracts });
  };

  const addContractField = () => {
    setFormData({ ...formData, contracts: [...formData.contracts, ''] });
  };
  
  const addSolutionSubmit = async () => {
    console.log({
      adder: accountname,
      affiliate: formData["affiliate"],
      affiliate_type: affiliatetype,
      description: formData["description"],
      solution_name: formData["solutionName"],
      socials: [
        { key: "email", value: formData?.affiliateEmail || ''},
        { key: "github", value: formData?.github || ''},
        { key: "twitter", value: formData?.twitter || ''},
        { key: "telegram", value: formData?.telegram || ''},
      ],
      code_type: codeType,
      contracts: formData?.contracts,
      links_to_code: formData?.linksToCode,
      affiliates_role: formData["affiliatesRole"],
      affiliate_email: formData["affiliateEmail"],
      category: category,
      logo_url: logourl,
      website:formData['website']
    })
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: isaffiliate ? "modsolution" : "addsolution",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                adder: accountname,
                affiliate: formData["affiliate"],
                affiliate_type: affiliatetype,
                description: formData["description"],
                solution_name: formData["solutionName"],
                socials: [
                  { key: "email", value: formData?.affiliateEmail || ''},
                  { key: "github", value: formData?.github || ''},
                  { key: "twitter", value: formData?.twitter || ''},
                  { key: "telegram", value: formData?.telegram || ''},
                ],
                code_type: codeType,
                contracts: formData?.contracts,
                links_to_code: formData?.linksToCode,
                affiliates_role: formData["affiliatesRole"],
                affiliate_email: formData["affiliateEmail"],
                category: category,
                logo_url: logourl,
                website:formData['website'],
                referrer: localStorage.getItem('referrerwax') || ""

              }
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sweetalert(isaffiliate ? "Solution modified." : "Solution submitted for review.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
      } catch (error) {
        sweetalert(error.message)
        console.log(error)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  return (
      <div className="App">
        <div className="addsolution">
          <b style={{alignSelf:"flex-start"}}>{isaffiliate ? <span>Modify solution</span> : <span>Add solution (+{rewards?.solution_reward} NCO)</span>}</b>
          <Accordion trigger={"Read about the action..."} text={isaffiliate ? "The affiliate is able to modify information regarding their solution. Modification can only be done by the affiliate that added the solution." : "Verified affiliates get rewarded in NCO tokens and have the opportunity to receive feedback on their solutions from verified users. EOSDEN core members will review your solution and execute the msig eosden::addsolution if approved. Once approved, you will be notified by e-mail.\n\n Once your solution gets approved, you will be able to send an offer to collaborate with another affiliate. Similarly, other affiliates can send offers to collaborate with you. If the collaboration is accepted, the affiliates are rewarded in NCO tokens."}/> 
          <hr className="solution-splitter"/>
          <b style={{alignSelf:"flex-start"}}>Basic info</b>
          <Accordion trigger="Read about how to fill..." text={"Please fill out your basic info. Email is mandatory. \n\n Pick the affiliate type:\n\n Web3 Solution Provider: you are a dApp developer\n\n Networker/advertiser: you want to help EOSDEN Web3 Solution Provider or Content Creators to have visibility on the socials\n\n Content creator: you create NFTs or any WEB3 content in relation with Antelope Networks"} />
          <TextField
            onChange={handleChangeaffiliatetype}
            value={affiliatetype}
            name="affiliateType"
            id="affiliateType"
            label="Affiliate type"
            select
            variant="outlined"
            sx={{ width: "100%" }}
          >
            {affiliateTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField onChange={handleBlur} value={formData?.affiliate} id="affiliate" label="Affiliate" variant="outlined" sx={{width:"100%"}} />
          <TextField onChange={handleBlur} value={formData?.solutionName} id="solutionName" label="Solution name" variant="outlined" sx={{width:"100%"}} />
          <TextField onChange={handleBlur} value={formData?.description} id="description" label="Description" multiline="true" minRows="2" variant="outlined" sx={{width:"100%"}} />
          <TextField value={formData?.affiliatesRole} onChange={handleBlur} id="affiliatesRole" label="Affiliates role" variant="outlined" sx={{width:"100%"}} />
          <TextField value={formData?.affiliateEmail} onChange={handleBlur} id="affiliateEmail" label="Affiliate email - mandatory" variant="outlined" sx={{width:"100%"}} />
          <TextField
            onChange={handleChangecategory}
            value={category}
            name="category"
            id="category"
            label="Category"
            select
            variant="outlined"
            sx={{ width: '100%' }}
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField value={formData?.website} onChange={handleBlur} id="website" label="Website" variant="outlined" sx={{width:"100%"}} />
          <hr className="solution-splitter"/>
          <b style={{alignSelf:"flex-start"}}>Socials</b>
          <Accordion trigger="Read about how to fill..." text={"Write your account name on each platform. No need for @ or the url."} />
          <TextField onChange={handleBlur} value={formData?.twitter} id="twitter" label="Twitter" variant="outlined" sx={{width:"100%"}} />
          <TextField onChange={handleBlur} value={formData?.telegram} id="telegram" label="Telegram" variant="outlined" sx={{width:"100%"}} />
          <TextField onChange={handleBlur} value={formData?.github} id="github" label="Github" variant="outlined" sx={{width:"100%"}} />
          <hr className="solution-splitter"/>
          <b style={{alignSelf:"flex-start"}}>Code</b>
          <Accordion trigger="Read about how to fill..." text={"Code type is about how much of your code you've open-sourced. \n Contracts are the contract accounts. \n Links to code should be full github repo links."} />
          <TextField
            onChange={handleChangecodetype}
            value={codeType}
            name="codeType"
            id="codeType"
            label="Code type"
            select
            variant="outlined"
            sx={{ width: '100%' }}
          >
            {code_types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {formData.contracts.map((contract, index) => (
            <TextField
              key={index}
              value={contract}
              onChange={(e) => handleContractChange(index, e.target.value)}
              label={`Contract ${index + 1}`}
              variant="outlined"
              sx={{ width: "100%", marginBottom: "8px" }}
            />
          ))}
          <button className="addsolutionvalue" onClick={addContractField}>Add more contracts</button>      
          {formData?.linksToCode?.map((link, index) => (
            <TextField
              key={index}
              value={link}
              onChange={(e) => handleLinksToCodeChange(index, e.target.value)}
              label={`Link to code ${index + 1}`}
              variant="outlined"
              sx={{ width: "100%", marginBottom: "8px" }}
            />
          ))}
          <button className="addsolutionvalue" onClick={addLinksToCodeField}>Add more links to code</button>
          <hr className="solution-splitter"/>
          <b style={{alignSelf:"flex-start"}}>Media</b>
          <Accordion trigger="Read about how to fill..." text={"Upload an image, we'll store it in our database, and the link will go in our contract tables."} />
          <TextField onChange={handleBlur} id="logo_url" value={logourl} label="Logo url" variant="outlined" sx={{width:"100%"}} />
          <FileUpload setLogourl={setLogourl}/>
          <hr className="upload-splitter"/>
          <button className="modal-submit" onClick={()=> addSolutionSubmit()}>{isaffiliate ? <span>Submit changes</span> : <span>Submit for review</span>}</button>
        </div>
    </div>
    
  );
};

export default Addsolution;

