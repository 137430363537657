import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UALProvider } from 'ual-reactjs-renderer'
import { Anchor } from 'ual-anchor'
import { Wombat } from 'ual-wombat'
import { Wax } from "@alienworlds/ual-wax";

const endpointlist = ["https://api.waxsweden.org","https://wax.eosdac.io", "https://wax.pink.qq", "https://api.wax.bountyblok.io", "https://chain.wax.io", "https://wax.eosusa.io"]
let endpoint;

endpoint = localStorage.getItem("endpoint")

const appName = "EOSDen";

const chain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "wax.eosusa.io",
      port: "",
    },
  ],
};

const anchor = new Anchor([chain], {
  appName,
});

const wax = new Wax([chain]);
const wombat = new Wombat([chain], { appName: 'EOSDen' })


const supportedChains = [chain];
const supportedAuthenticators = [wax, anchor, wombat];


// Define an async function to handle your fetch request
async function checkEndpoint(item) {
  try {
    let res = await fetch(`${endpointlist[item]}/v1/chain/get_table_rows`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        json: true,
        code: "pollpollpoll",
        table: "divperiod",
        scope: "pollpollpoll",
        limit: 1,
      }),
    });
    if (res.ok) {
      localStorage.setItem("endpoint", endpointlist[item]);
      endpoint = endpointlist[item];
      console.log("Endpoint works for you:" + endpointlist[item]);
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
          <UALProvider
            chains={supportedChains}
            authenticators={supportedAuthenticators}
            appName={appName}
          >
            <App />
          </UALProvider>
      );    } else {
      console.log("Endpoint does not work for you:" + endpointlist[item]);
    }
  } catch (error) {
    console.log("Endpoint seems to be down:" + endpointlist[item]);
  }
}

if (localStorage.getItem("endpoint") === null) {
  for (let item in endpointlist) {
    checkEndpoint(item);
  }
} else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
      <UALProvider
        chains={supportedChains}
        authenticators={supportedAuthenticators}
        appName={appName}
      >
        <App />
      </UALProvider>
  );
}
